<template>
  <div class="flex items-center text-sm justify-left">
    <p class="font-bold" style="font-size: 0.8rem !important">Elaborado por</p>
    <img
      src="../../assets/img/logo-easy-testes.svg"
      style="height: 18px"
      :class="imageClasses"
    />
  </div>
</template>

<script>
export default {
  name: 'PoweredBy',
  data() {
    return {
      platformName: null,
      platformLogo: null,
      imageClasses: null,
    };
  },
  created() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.platformName = 'ComplianceOnline';
      this.platformLogo = '/img/logo-compliance-escuro.svg';
      this.imageClasses = 'ml-2 h-3';
    } else {
      this.platformName = 'EasyTermos';
      this.platformLogo = '../../assets/img/logo-easy-testes.svg';
      this.imageClasses = 'ml-2 h-5';
    }
  },
};
</script>

<style scoped></style>
